import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import UserContext from "../../context/UserContext";

export default function AuthOptions() {
    const { userData, setUserData } = useContext(UserContext);
    const history = useNavigate();

    const register = () => {
        history('/admin/register');
    };

    const login = () => history('/admin/login');
    const logout = () => {
        setUserData({
            token: undefined,
            user: undefined,
        });
        localStorage.setItem("auth-token", "");
        localStorage.setItem("user", "");
        history('/admin/login');
    };

    const pdffiles = () => {
        history('/file-list');
    };

    const upload = () => {
        history('/upload');
    };
    return (
        <nav className="auth-options">
            {
                userData.user ? (
                    <>
                        <button onClick={upload}>Subir</button>
                        <button onClick={pdffiles}>Lista PDFs</button>
                        <button onClick={register}>Usuarios</button>
                        <button onClick={logout}>Log out</button>
                    </>
                ) : (
                    <>
                        <button onClick={login}>Log in</button>
                    </>
                )
            }
        </nav>
    )
}

import "core-js/stable";
import "regenerator-runtime/runtime";

import React, { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./components/pages/Home";
import List from "./components/pages/List";
import Axios from 'axios';
import UserContext from './context/UserContext';
import Login from './components/auth/Login';
import Register from './components/auth/Register';
import Header from "./components/layout/Header";
import Upload from "./components/pages/Upload";
import UpdateUser from "./components/pages/UpdateUser";

import "./App.css";
import "./styles/form.css";
import "./styles/button.css";


function App() {
  const [userData, setUserData] = useState({
    token: undefined,
    user: undefined,
    role: undefined
  });

  useEffect(() => {
    const checkLoggedIn = async () => {
      let token = localStorage.getItem("auth-token");
      if (!token) {
        localStorage.setItem("auth-token", "");
        token = "";
      }
  
      try {
        const tokenRes = await Axios.post(
          `${process.env.REACT_APP_BACK_DOMAIN}/users/tokenIsValid`,
          null,
          { headers: { "x-auth-token": token } }
        );
  
        if (tokenRes.data.valid) {
          const userRes = await Axios.get(`${process.env.REACT_APP_BACK_DOMAIN}/users`, {
            headers: { "x-auth-token": token },
          });
          setUserData({
            token,
            user: userRes.data,
            role: userRes.data.role,
          });
        } else {
          localStorage.setItem("auth-token", "");
          setUserData({ token: undefined, user: undefined, role: undefined });
          // Redirigir al login si es necesario
        }
      } catch (err) {
        console.error("Error al validar el token:", err);
        // Limpiar y redirigir si ocurre un error
        localStorage.setItem("auth-token", "");
        setUserData({ token: undefined, user: undefined, role: undefined });
        // window.location.href = "/admin/login";
      }
    };
  
    checkLoggedIn();
  }, []);

  return (
    <>
      <BrowserRouter>
        <UserContext.Provider value={{ userData, setUserData }}>
          <Header />
            <Routes>
              <Route path="/" exact element={<Home />} />
              <Route path="/admin/login" exact element={<Login />} />
              <Route path="/admin/register" exact element={<Register />} />
              <Route path="/admin/update" exact element={<UpdateUser />} />
              <Route path="/file-list" exact element={<List /> } />
              <Route path="/upload" exact element={<Upload />} />
            </Routes>
        </UserContext.Provider>
      </BrowserRouter>
    </>
  );
}

export default App;
import React, { Component } from "react";
import axios from "axios";
import { Link } from "react-router-dom";

class List extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      filesObj: [],
    };
  }
  componentDidMount() {
    axios.post(`${process.env.REACT_APP_BACK_DOMAIN}/files_consult/files-list`).then(
      (result) => {
        this.setState({
          filesObj: result.data,
        });
      },
      (error) => {
        this.setState({
          error: true,
        });
      }
    );
  }
  render() {
    const { error, filesObj } = this.state;

    return (
      <div>
        <div className="data-table">
          <table className="table table-bordered table-striped">
            <thead>
              <tr>
                <th className="text-center"></th>
                <th className="text-center">Nombre </th>
                <th className="text-center">Fecha </th>
                <th className="text-center">Codigo embebido</th>
              </tr>
            </thead>
            <tbody>
              {filesObj.map((att, i) => (
                <tr key={i}>
                  <th>{i + 1}</th>
                  <td>{att.name}</td>
                  <td>{att.correct_date}</td>
                  <td>
                    &lt;iframe src="https://docs.google.com/viewer?url={att.url}
                    &embedded=true" width="100%" height="500" style="border:
                    none;"&gt;&lt;/iframe&gt;
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default List;

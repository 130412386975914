export function validateToken(token) {
    if (token) {
      return new Promise((resolve, reject) => {
        fetch(`${process.env.REACT_APP_BACK_DOMAIN}/users/tokenIsValid`, {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'x-auth-token': `${token}`,
            'Content-Type': 'application/json',
          },
        })
          .then(res => res.json())
          .then(data => {
            resolve(data);
          })
          .catch(err => reject(err));
      });
    }
  
  }